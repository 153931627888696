import React from "react";
import Helmet from "react-helmet";
import { Flex, Box } from '@rebass/grid';
import Disqus from 'disqus-react';
import {graphql} from 'gatsby'

import '../sass/blog-post.scss';

const Template = ({data}) => {
  const { markdownRemark: post } = data;
  const disqusShortname = 'alexandre-gomes';
  const disqusConfig = {
    url: `http://alexandregomes.fr${post.frontmatter.path}`,
    identifier: post.frontmatter.path,
    title: post.frontmatter.title,
  };

  return (
    <Flex className="blog-post-container" justifyContent='center'>
      <Helmet title={`Alexandre Gomes - ${post.frontmatter.title}`} />
      <Box width={[9/10, 5/6, 1/2]} className="blog-post">
        <div>
          <h2 className="
			blog-post--title font-bold font-sans break-normal pt-6 pb-2 text-3xl md:text-4xl
			">{post.frontmatter.title}</h2>
          <h3 className="blog-post--meta">Posted on {post.frontmatter.date}</h3>
        </div>
        <div
          className="blog-post--content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <div className="post-comments">
          <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </Box>
    </Flex>
  );
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }`;

export default Template;
